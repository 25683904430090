import React, { Component } from "react";
import closeBtn from '../assets/close.png';
import { Col, Row } from 'react-bootstrap';
// import imgLogo from '../assets/IMG_LOGO.jpg';

class Popup extends Component {

    activityDiv(activity) {
        if (activity.length) {
            let activityListDiv = () => {
                return activity.map((activityItem, index) =>
                    <div className="tags" key={index}>#{activityItem.name}</div>
                )
            }

            return (
                <>
                    <div className="row-splitter"></div>
                    <div className="label">Activité de l'association :</div>
                    {
                        activityListDiv()
                    }
                </>
            );
        }
        return <></>;
    }

    categoryDiv(category) {
        if (category.length) {
            let categoryListDiv = () => {
                return category.map((categoryItem, index) =>
                    <div className="tags" key={index}>#{categoryItem.category_name}</div>
                )
            }

            return (
                <>
                    <div className="row-splitter"></div>
                    <div className="label">Catégorie de l'association :</div>
                    {
                        categoryListDiv()
                    }
                </>
            );
        }
        return <></>;
    }

    render() {
        const association = this.props.data;
        return (
            <div className='popup'>
                <div className='popup_inner'>
                    <Row>
                        <Col md={3}>
                            <Row>
                                {
                                    association.logo ? <img className="pop-up-logo" src={association.logo} alt="Logo" width="237" height="125" /> : null
                                }

                            </Row>
                            <div className="pop-up-left-column">

                                <div className="row-splitter"></div>

                                <div className="label">Age pour l'adhésion :</div>
                                <div className="tags">{association.age_min} ans - {association.age_max} ans</div>

                                {/*{association.pmr ? */}
                                {/*    <>*/}
                                {/*        <div className="row-splitter"></div> */}
                                {/*        <div className="tags">PMR</div> */}
                                {/*    </>*/}
                                {/*: null*/}
                                {/*}*/}

                                {
                                    this.activityDiv(association.activity)
                                }

                                {
                                    this.categoryDiv(association.category)
                                }

                                {association.flyer ? <img className="pop-up-flyer mt-4" src={association.flyer} alt="Logo" /> : null}

                            </div>
                        </Col>

                        <Col>
                            <div className="popup-content">
                                <h2>{association.name_association}</h2>
                                <h4>{association.acronyme}</h4>

                                <div className="location">
                                    {association.location ? association.location : null}

                                    {association.site_web ? <div className="contact">{association.site_web}</div> : null}
                                    {association.email ? <div className="contact">{association.email}</div> : null}
                                    {association.phone_number ? <div className="contact">{association.phone_number}</div> : null}
                                    {association.contact_name ? <div className="contact">{association.contact_name}</div> : null}
                                    {association.facebook_url ? <div className="contact">{association.facebook_url}</div> : null}
                                </div>

                                <p>{association.description}</p>
                                <Row xs={12}>
                                    <Col>
                                        {
                                            association.youtube_url ? <iframe title="video" key={association.id} width="400" height="245" src={association.youtube_url}></iframe> : null
                                        }
                                    </Col>
                                </Row>


                            </div>

                        </Col>
                    </Row>


                    <img onClick={this.props.closePopup()} className="close-btn" src={closeBtn} alt="close" />
                </div>
            </div>
        );
    }
}

export default Popup;
