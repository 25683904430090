import React from "react";
import Title from './components/HeaderComponent';
import BodyComponent from './components/BodyComponent';
import './App.css';
import { Container } from 'react-bootstrap';


function App() {
  return (
    <div className="App">
      <Container>
        <Title></Title>
        <BodyComponent></BodyComponent>
      </Container>
    </div>
  );
}

export default App;
