import React from "react";
import { Row, Col } from 'react-bootstrap';

const Title = () => {
    return (
        <>
            <Row>
                <Col className="header">
                    <h1>Vous recherchez une activité associative ?</h1>
                </Col>
            </Row>
            <Row className="sub-header">
                <Col md={3}>
                    <h2> </h2>
                </Col>
                
            </Row>

        </>
    );
}

export default Title;