import React, { Component } from "react";
import { Row, Col, ListGroup, Collapse } from 'react-bootstrap';
import Loader from "./LoaderComponent";
import axios from "axios";

class FilterComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading_state: true,
            categorieFilterTray: false,
            subCategorieFilterTray: false,
            ActivityFilterTray: false,
            AgeFilterTray: false,
            category_filter: null,
            sub_category_filter: null,
            activity_filter: null,
            age_filter: null,
            selected_category: null,
            selected_sub_category: null,
            selected_activity: null,
            selected_age_option: null,
            selected_age: null,
            associationList: null,
            association_name_list: [],
            alphabets_filter: null,
            selected_alphabet_filter: null,
            seclected_activity_name: null,
            selected_category_name: null,
            selected_sub_category_name: null,
            msg: null,
            ageRange: this.range(0,100)
        }
    }

    togglCategoryTray(mode) {
        this.setState({ ...this.state, categorieFilterTray: mode });
    }

    togglSubCategoryTray(mode) {
        this.setState({ ...this.state, subCategorieFilterTray: mode });
    }

    togglActivityFilterTray(mode) {
        this.setState({ ...this.state, ActivityFilterTray: mode });
    }

    togglAgeFilterTray(mode) {
        this.setState({ ...this.state, AgeFilterTray: mode });
    }

    componentDidMount() {
        this.renderAssociationFilteList();
    }

    async renderAssociationFilteList() {
        await axios.get('http://arcachon.napsy.com/web/api/association-filters?key=ec6b5a269172d72eac714cca678e1a699bc86092c714cc', { mode: 'cors' })
            .then(response => {
                let { message, payload } = response.data;
                if (response.status === 200) {
                    this.setState({ loading_state:false });
                    if (payload.activity_filter.length) {
                        this.setState({ activity_filter: payload.activity_filter})
                    }
                    if (payload.category_filter.length) {
                        this.setState({ category_filter: payload.category_filter })
                    }
                    if (payload.sub_category_filter.length) {
                        this.setState({ sub_category_filter: payload.sub_category_filter })
                    }
                    if (payload.age_filter.length) {
                        this.setState({ age_filter: payload.age_filter})
                    }
                    if (payload.association_list_name.length) {
                        this.setState({ association_name_list: payload.association_list_name })
                        this.set_alphbabet_filter();
                    }
                } else {
                    this.setState({ loading_state:false, msg: message })
                }
            })
            .catch(error => {
                this.setState({ loading_state:false, msg: "Error Retrieving Data. Please try again." });
                console.log(error);
            })
    }

    async categoryFilterSelect(key, cat_name) {
        if (key > 0) {
            this.setState({
                selected_category: key,
                selected_category_name: cat_name,
                selected_sub_category: null,
                selected_sub_category_name: null,
                selected_activity: null,
                seclected_activity_name: null,
                selected_age: null,
                selected_age_option: null
            }, () => this.callFilterRequest());
        }
    }

    subCategoryFilterSelect(key, subcat_name) {
        if (key > 0) {
            this.setState({
                ...this.state,
                selected_sub_category: key,
                selected_sub_category_name: subcat_name,
                selected_age: null,
                selected_age_option: null
            }, () => this.callFilterRequest());
        }
    }

    ageFilterSelect(position, age) {
        if (age >= 0 && age <= 99) {
            this.setState({
                ...this.state,
                selected_age_option: position,
                selected_age: age,
            }, () => this.callFilterRequest());
        }
    }

    activityFilterSelect(key, activityName) {
        if (key > 0) {
            this.setState({
                ...this.state,
                selected_activity: key,
                seclected_activity_name: activityName,
                selected_age: null,
                selected_age_option: null
            }, () => this.callFilterRequest());
        }
    }

    clearFilterState() {
        window.location.reload(false);
    }

    set_alphbabet_filter() {
        let associationNames = this.state.association_name_list;
        let alphabet_array = [];
        if (associationNames.length) {
            for (let i = 0; i < associationNames.length; i++) {
                let fl = associationNames[i].charAt(0);
                if (!alphabet_array.includes(fl)) {
                    alphabet_array.push(fl)
                }
            }
            this.setState({
                ...this.state,
                alphabets_filter: alphabet_array
            })
        }
    }

    function_div_sub_cat() {
        // let { sub_category_filter, selected_category, subCategorieFilterTray, selected_sub_category } = this.state;
        //
        // if (selected_category !== null) {
        //     let filtered_sub_cat = sub_category_filter.filter(function (sub_category) { return sub_category.parent === selected_category });
        //     if (filtered_sub_cat.length > 0) {
        //         return (
        //             <Row className="filterTray">
        //                 <div className="label"
        //                     onClick={() => this.togglSubCategoryTray(!subCategorieFilterTray)}
        //                     aria-controls="sub-category-tray-listGroup"
        //                     aria-expanded={subCategorieFilterTray}
        //                 >
        //                     SOUS CATEGORIE
        //                 </div>
        //                 <Collapse in={subCategorieFilterTray}>
        //                     <ListGroup id="sub-category-tray-listGroup">
        //                         {
        //                             filtered_sub_cat && filtered_sub_cat.length && filtered_sub_cat.map((sub_category) =>
        //                                 sub_category.parent === selected_category ? (
        //                                     <ListGroup.Item
        //                                         onClick={() => this.subCategoryFilterSelect(sub_category.id, sub_category.name)}
        //                                         className={sub_category.id === selected_sub_category ? "active" : null}
        //                                         key={sub_category.id}>
        //                                         {sub_category.name}
        //                                     </ListGroup.Item>
        //                                 )
        //                                     :
        //                                     null
        //                             )
        //                         }
        //                     </ListGroup>
        //                 </Collapse>
        //             </Row>
        //         )
        //     }
        // }
        return <div></div>;
    }

    alphabetFilter(letter) {
        if (letter) {
            this.setState({
                ...this.state,
                selected_alphabet_filter: letter
            }, () => this.callFilterRequest())
        }
    }

    callFilterRequest() {
        let filterObject = {};
        let breadCrumbsNames = {};

        let {
            selected_activity, selected_category, selected_sub_category,
            selected_age, selected_alphabet_filter, seclected_activity_name,
            selected_sub_category_name, selected_category_name
        } = this.state;

        filterObject = {
            'activity': selected_activity,
            'category': selected_category,
            'sub_category': selected_sub_category,
            'age': selected_age,
            'starts_with': selected_alphabet_filter
        };

        breadCrumbsNames = {
            'activity_name': seclected_activity_name,
            'category_name': selected_category_name,
            'sub_category_name': selected_sub_category_name,
            'age': selected_age,
            'starts_with': selected_alphabet_filter
        }

        this.props.filterby(filterObject, breadCrumbsNames);
    }



    range(start, end) {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }

    render() {
        let { categorieFilterTray, ActivityFilterTray, category_filter, ageRange,
            activity_filter, selected_age_option, msg, selected_activity, selected_category,
            AgeFilterTray, alphabets_filter, selected_alphabet_filter, loading_state } = this.state;

        // let sub_cat_div = this.function_div_sub_cat();
        if(this.props.activity_filter && this.props.ageRange){
            activity_filter = this.props.activity_filter
            ageRange = this.range(this.props.ageRange[0],this.props.ageRange[1])
        }

        return (

            <>
                {
                    loading_state ? <><Loader></Loader></> :

                        <>
                            {msg ? <div>{msg}</div> : null}
                            <div>
                                <Row>
                                    <div className="label filter-header">
                                        Index des associations
                                    </div>
                                </Row>
                                <Row className="filterTray">
                                    <div className="label clearBtn" onClick={() => this.clearFilterState()}>
                                        Nouvelle recherche
                                    </div>
                                </Row>
                                <Row className="filterTray">
                                    <div className="label"
                                        onClick={() => this.togglCategoryTray(!categorieFilterTray)}
                                        aria-controls="category-tray-listGroup"
                                        aria-expanded={categorieFilterTray}
                                    >
                                        CATEGORIE
                                    </div>
                                    <Collapse in={categorieFilterTray}>
                                        <ListGroup id="category-tray-listGroup">
                                            {
                                                category_filter && category_filter.length && category_filter.map((category) =>
                                                    <ListGroup.Item
                                                        onMouseUp={() => this.categoryFilterSelect(category.id, category.name)}
                                                        className={category.id === selected_category ? "active" : null}
                                                        key={category.id}>
                                                        {category.name}
                                                    </ListGroup.Item>
                                                )
                                            }
                                        </ListGroup>
                                    </Collapse>
                                </Row>
                                <Row className="filterTray">
                                    <div className="label"
                                        onClick={() => this.togglActivityFilterTray(!ActivityFilterTray)}
                                        aria-controls="activity-tray-listGroup"
                                        aria-expanded={ActivityFilterTray}
                                    >
                                        ACTIVITE
                                    </div>
                                    <Collapse in={ActivityFilterTray}>
                                        <ListGroup id="activity-tray-listGroup">
                                            {
                                                activity_filter && activity_filter.length && activity_filter.map((activity) =>
                                                    <ListGroup.Item
                                                        onClick={() => this.activityFilterSelect(activity.id, activity.name)}
                                                        className={activity.id === selected_activity ? "active" : null}
                                                        key={activity.id}>
                                                        {activity.name}
                                                    </ListGroup.Item>
                                                )
                                            }
                                        </ListGroup>
                                    </Collapse>
                                </Row>

                                {/* {
                                    sub_cat_div
                                } */}

                                <Row className="filterTray">
                                    <div className="label"
                                        onClick={() => this.togglAgeFilterTray(!AgeFilterTray)}
                                        aria-controls="age-tray-listGroup"
                                        aria-expanded={AgeFilterTray}
                                    >
                                        AGE
                                    </div>
                                    <Collapse in={AgeFilterTray}>
                                        <ListGroup id="age-tray-listGroup">
                                            {ageRange.map((age) =>
                                                <ListGroup.Item
                                                    onClick={() => this.ageFilterSelect(age, age)}
                                                    className={age === selected_age_option ? "active" : null}
                                                    key={age}>
                                                    {age}
                                                </ListGroup.Item>
                                            )}
                                        </ListGroup>
                                    </Collapse>
                                </Row>
                                <Row className="alphabetSearchBox-container">
                                    {
                                        alphabets_filter && alphabets_filter.length && alphabets_filter.map((letter, index) =>
                                            <Col key={index} xs={2} md={4} lg={3}>
                                                <div onClick={() => this.alphabetFilter(letter)} key={index}
                                                     className={selected_alphabet_filter === letter ? "alphabetSearchBox-active" : "alphabetSearchBox"}>
                                                    {letter}
                                                </div>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </div>
                        </>
                }
            </>
        );
    }
}

export default FilterComponent;
