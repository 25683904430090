import React, { Component } from "react";
import FilterComponent from "./FilterComponent";
import axios from "axios";
import AssociationListComponent from "./AssociationListComponent";
import Loader from "./LoaderComponent";
import { Row, Col } from 'react-bootstrap';

class BodyComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            msg: '',
            associationList: null,
            loading_state: false,
            landing_page: true,
            applied_filter: {
                activity: 0,
                category: 0,
                sub_category: 0,
                age: 0,
                starts_with: null,
                activityName: null,
                categoryName: null,
                subCategoryName: null,
                age_label: null
            },
            activity_filter: null,
            ageRange: null
        }
    }

    componentDidMount() {
        // this.renderAssociationList();
    }

    resetFilter() {
        this.setState({
            ...this.state,
            loading_state: true,
            applied_filter: {
                ...this.state,
                activity: 0,
                category: 0,
                sub_category: 0,
                age: 0,
                starts_with: null,
                activityName: null,
                categoryName: null,
                subCategoryName: null,
                age_label: null
            },
            activity_filter: null,
            ageRange: null
        });
        this.renderAssociationList();
    }

    queryBuilder(filters, breadCrumbsNames) {
        let qBuilder = '';
        for (let row in filters) {
            if (filters[row] !== null) {
                if(row === "age"){
                    qBuilder += `&${row}=${filters[row]}`;
                }else if(filters[row] > 0){
                    qBuilder += `&${row}=${filters[row]}`;
                }
                if (row === "starts_with") {
                    qBuilder += `&${row}=${filters[row]}`;
                }
            }
        }

        let activityName = null;
        let categoryName = null;
        let subCategoryName = null;
        let age_label = null;
        let starts_with = null

        if (breadCrumbsNames.activity_name !== undefined && breadCrumbsNames.activity_name !== null) {
            activityName = breadCrumbsNames.activity_name;
        }

        if (breadCrumbsNames.category_name !== undefined && breadCrumbsNames.category_name !== null) {
            categoryName = breadCrumbsNames.category_name;
        }

        if (breadCrumbsNames.sub_category_name !== undefined && breadCrumbsNames.sub_category_name !== null) {
            subCategoryName = breadCrumbsNames.sub_category_name;
        }

        if (breadCrumbsNames.age !== undefined && breadCrumbsNames.age !== null) {
            age_label = breadCrumbsNames.age;
        }

        if (breadCrumbsNames.starts_with !== undefined && breadCrumbsNames.starts_with !== null) {
            starts_with = breadCrumbsNames.starts_with;
        }

        this.setState({
            ...this.state,
            loading_state: true, landing_page: false,
            applied_filter: {
                ...this.state,
                activityName: activityName,
                categoryName: categoryName,
                subCategoryName: subCategoryName,
                age_label: age_label,
                starts_with: starts_with,
            }
        });

        this.renderAssociationList(qBuilder);
    }

    async renderAssociationList(searchBy = '') {

        let queryUrl = `key=ec6b5a269172d72eac714cca678e1a699bc86092c714cc${searchBy}`;
        // console.log(queryUrl);
        await axios.get(`http://arcachon.napsy.com/web/api/association?${queryUrl}`, { mode: 'cors' }) // &activity=4
        .then(response => {
            if (response.status === 200) {
                let { message, payload } = response.data;
                if (payload.data.length) {
                    if (!searchBy) {
                        this.setState({
                            ...this.state,
                            msg: null,
                            associationList: payload.data,
                            loading_state: false,
                            landing_page: true
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            msg: null,
                            associationList: payload.data,
                            loading_state: false
                        });

                        const activityChange =  searchBy.includes('activity')

                        let activities = []
                        let minAge = 100
                        let maxAge = 0
                        payload.data.forEach(element => {
                            if(element.age_min < minAge) minAge = element.age_min
                            if(element.age_max > maxAge) maxAge = element.age_max
                            if(!activityChange){
                                element.activity.forEach(activity => {
                                    if(activities.find(a => a.id === activity.id) === undefined){
                                        activities.push(activity)
                                    }
                                });
                            }
                        });

                        if(activityChange){
                            activities = this.state.activity_filter
                        }

                        if(activities)
                        activities.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

                        this.setState({
                            activity_filter: activities,
                            ageRange: [minAge, maxAge]
                        })
                    }
                } else {
                    this.setState({ msg: message, associationList: null, loading_state: false })
                }
            } else {
                this.setState({ ...this.state, msg: 'Error Retrieving Data. Please try again.', loading_state: false })
            }
        })
        .catch(error => {
            this.setState({ ...this.state, msg: "Error Retrieving Data. Please try again.", loading_state: false })
            console.log(error);
        })
    }

    render() {
        const { associationList, msg, loading_state, landing_page, activity_filter, ageRange } = this.state;

        let { starts_with, activityName, categoryName, subCategoryName, age_label } = this.state.applied_filter;
        return (
            <>
                <div className="boxBody">
                    <Row>
                        <Col md={3} xs={12}>
                            <FilterComponent 
                            data={associationList} 
                            resetFilter={() => this.resetFilter()} 
                            filterby={(filters, breadCrumbsNames) => this.queryBuilder(filters, breadCrumbsNames)}
                            activity_filter = {activity_filter}
                            ageRange = {ageRange}
                            ></FilterComponent>
                        </Col>
                        <Col md={9} xs={12} className='strechMargin'>
                            {
                                landing_page === true ? (
                                    <div className="landing-page"></div>
                                ) :
                                    loading_state === true ? (
                                        <Loader />
                                    )
                                        :
                                        <>
                                            <div className="filterBreadCrumbs">
                                                {
                                                    activityName ? (
                                                        <span>
                                                            {activityName}
                                                            <span className="splitter"> | </span>
                                                        </span>
                                                    ) : null
                                                }

                                                {
                                                    categoryName ? (
                                                        <span>
                                                            {categoryName}
                                                            <span className="splitter"> | </span>
                                                        </span>
                                                    ) : null
                                                }

                                                {
                                                    subCategoryName ? (
                                                        <span>
                                                            {subCategoryName}
                                                            <span className="splitter"> | </span>
                                                        </span>
                                                    ) : null
                                                }

                                                {
                                                    starts_with ? (
                                                        <span>
                                                            "{starts_with}"
                                                            <span className="splitter"> | </span>
                                                        </span>
                                                    ) : null
                                                }

                                                {
                                                    age_label ? (
                                                        <span>
                                                            {age_label} ans
                                                        </span>
                                                    ) : null
                                                }
                                            </div>
                                            <AssociationListComponent data={associationList} msg={msg}></AssociationListComponent>
                                        </>
                            }
                        </Col>
                    </Row>
                </div>
            </>
        )
    }


}

export default BodyComponent;
