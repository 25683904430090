import React, { Component } from "react";
import {Card, CardGroup, Col, Row} from 'react-bootstrap';
import Popup from './PopUpComponent';
import PopUpModalComponent from './PopUpModalComponent';

class AssociationListComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showPopup: false,
            selectedTile: {}
        }
        this.togglePopup = this.togglePopup.bind(this)
    }


    togglePopup(data) {
        this.setState({
            showPopup: !this.state.showPopup,
            selectedTile: data
        });
    }

    closePopUp(){
        this.setState({
            showPopup: false
        })
    }

    render() {
        const { showPopup, selectedTile } = this.state;
        const associationList = this.props.data;
        return (
            <Row style={{marginTop:"1.85em"}}>
                <CardGroup className="contentScrollBoxR">
                    {
                        associationList && associationList.length && associationList.map((data, index) =>
                        <Col md={4} xs={12} className="associations" key={data.name_association+'|'+data.id}>
                            <Card key={index} onClick={() => this.togglePopup(data)} style={{width:"100%",padding:"5px"}}>
                                <Card.Img variant="top" src={data.logo}  />
                                <Card.Body className="pb-1 pt-1">
                                    {data.name_association.length < 56 ?
                                        <Card.Title
                                            className="fix-max-height">{data.name_association}</Card.Title>
                                        :
                                        <Card.Title
                                            className="fix-max-height">{data.name_association.substring(0, 56) + '...'}</Card.Title>
                                    }
                                    <div className="age">{data.age_min} ans - {data.age_max} ans</div>
                                </Card.Body>
                                <Card.Footer className="pt-0">
                                    {
                                        data.activity.map((activity, index) =>
                                            <small key={activity.id} className="text-muted activityTags">#{activity.name}</small>
                                        )
                                    }
                                </Card.Footer>
                            </Card>
                        </Col>
                        )
                    }
                    {this.props.msg ? <div>{this.props.msg}</div> : null}
                </CardGroup>
                {showPopup ?
                    // <Popup
                    //     data={selectedTile}
                    //     closePopup={() => this.togglePopup}
                    // />
                    <PopUpModalComponent
                        data={selectedTile}
                        closePopup={()=>this.closePopUp()}
                    />
                    : null
                }
            </Row>
        );
    }
}

export default AssociationListComponent;
