import React, {Fragment, useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import {Col, Row} from "react-bootstrap";


export default function PopUpModalComponent(props){

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        return props.closePopup()
    }
    const handleShow = () => setShow(true);

    useEffect(()=>{
        console.log(props.data)
        handleShow()
    },[])

    const activityDiv = (activity) => {
        if (activity.length) {
            let activityListDiv = () => {
                return activity.map((activityItem, index) =>
                    <div className="tags" key={index}>#{activityItem.name}</div>
                )
            }

            return (
                <>
                    <div className="modal-row-splitter"></div>
                    <div className="label">Activité de l'association :</div>
                    {
                        activityListDiv()
                    }
                </>
            );
        }
        return <></>;
    }

    const categoryDiv = (category) => {
        if (category.length) {
            let categoryListDiv = () => {
                return category.map((categoryItem, index) =>
                    <div className="tags" key={index}>#{categoryItem.category_name}</div>
                )
            }

            return (
                <>
                    <div className="modal-row-splitter"></div>
                    <div className="label">Catégorie de l'association :</div>
                    {
                        categoryListDiv()
                    }
                </>
            );
        }
        return <></>;
    }

    return (
        <Fragment>
            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show} onHide={handleClose}
            >
                <Modal.Header closeButton/>
                <Modal.Body>
                    <Row>
                        <Col md={3} xs={12}>
                            <Row>
                                {
                                    props.data.logo ? <img className="pop-up-logo" src={props.data.logo} alt="Logo" width="237" height="125" /> : null
                                }
                            </Row>

                            <div className="modal-up-left-column">

                                <div className="modal-row-splitter"></div>

                                <div className="label">Age pour l'adhésion :</div>
                                <div className="tags">{props.data.age_min} ans - {props.data.age_max} ans</div>

                                {
                                    activityDiv(props.data.activity)
                                }

                                {
                                    categoryDiv(props.data.category)
                                }

                                {props.data.flyer ? <img className="modal-flyer mt-3 mb-3" src={props.data.flyer} alt="Logo" /> : null}

                            </div>
                        </Col>
                        <Col md xs={12}>

                            <div className="modal-content">
                                <h2>{props.data.name_association}</h2>
                                <h4>{props.data.acronyme}</h4>

                                <div className="location">
                                    {props.data.location ? props.data.location : null}

                                    {props.data.site_web ? <div className="contact">{props.data.site_web}</div> : null}
                                    {props.data.facebook_url ? <div className="contact">{props.data.facebook_url.replace('fr-','')}</div> : null}
                                    {props.data.contact_name ? <div className="contact">{props.data.contact_name}</div> : null}
                                    {props.data.phone_number ? <div className="contact">{props.data.phone_number}</div> : null}
                                    {props.data.email ? <div className="contact">{props.data.email}</div> : null}
                                </div>

                                <p style={{ whiteSpace: 'pre-line' }}>{props.data.description}</p>
                                <Row xs={12}>
                                    <Col>
                                        {
                                            props.data.youtube_url ? <iframe title="video" key={props.data.id} width="400" height="245" src={props.data.youtube_url}></iframe> : null
                                        }
                                    </Col>
                                </Row>


                            </div>

                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}